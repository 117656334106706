export const emailRegex = /^([-a-zA-Z0-9_.]+)@([-a-zA-Z0-9_.]+)\.([a-zA-Z]{2,5})$/;

export const minLengthRegex = new RegExp(`^.{2,}$`);

export const errorForCurrencyInput = (amount?: number) => {
  if (!amount || isNaN(amount) || amount <= 0) {
    return 'Enter a valid dollar amount.';
  }

  if (amount < 1) {
    return 'Enter a value of at least $1.';
  }
};
