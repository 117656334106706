import { createSelector } from 'reselect';
import { RootState } from '../..';
import { builderIsValid, buildGiftUnsafe } from '../../../models/GiftBuilder';
import { GIFT_TYPE_SINGLE } from '../../../models/GiftType';
import { getSingleGiftFrequencyOption } from '../../../util/dataSets/frequencies';
import GiftFrequencies from '../../../../assets/values/singleGiftFrequencies.json';

const getCartState = (state: RootState) => state.cart;

const getSkusToBuilders = createSelector(
  [getCartState],
  state => state.builders.SINGLE
);

export const getSharedAttributes = createSelector(
  [getCartState],
  state => state.attributes.SINGLE
);

export const getBuilders = createSelector([getSkusToBuilders], skusToBuilders =>
  Object.values(skusToBuilders)
);

export const getTotalAmount = createSelector([getBuilders], builders =>
  builders.reduce((sum, builder) => sum + (builder.amount || 0), 0)
);

export const getItemCount = createSelector(
  [getBuilders],
  builders => builders.length
);

export const getFrequency = createSelector([getSharedAttributes], attributes =>
  getSingleGiftFrequencyOption(attributes.frequency)
);

export const getDurations = createSelector(
  [getSharedAttributes],
  attributes => getSingleGiftFrequencyOption(attributes.frequency)?.duration
);

export const getDuration = createSelector(
  [getSharedAttributes],
  attributtes => attributtes.duration
);

export const getCartIsValid = createSelector(
  [getBuilders, getSharedAttributes],
  (builders, singleGiftSharedAttributes) => {
    const frequency = getSingleGiftFrequencyOption(
      singleGiftSharedAttributes.frequency
    );
    const duration = singleGiftSharedAttributes.duration;

    return (
      builders.length > 0 &&
      builders.every(b =>
        builderIsValid(b, { [GIFT_TYPE_SINGLE]: singleGiftSharedAttributes })
      ) &&
      (frequency?.value === GiftFrequencies.oneTime.value ||
        (frequency?.value === GiftFrequencies.monthly.value &&
          duration !== undefined) ||
        (frequency?.value === GiftFrequencies.annually.value &&
          duration !== undefined))
    );
  }
);

export const getCompletedGifts = createSelector(
  [getBuilders, getSharedAttributes],
  (builders, singleAttributes) => {
    const attributes = { [GIFT_TYPE_SINGLE]: singleAttributes };

    return builders
      .filter(b => builderIsValid(b, attributes))
      .map(b => buildGiftUnsafe(b, attributes));
  }
);

export const getBuilderWithSkuCreator = () =>
  createSelector(
    [getSkusToBuilders],
    skusToBuilders => (sku: string) => skusToBuilders[sku]
  );

export const getGivingItemWithSkuCreator = () =>
  createSelector([getBuilderWithSkuCreator()], getBuilder => (sku: string) => {
    return getBuilder(sku).givingItem;
  });

export const hasGivingItemWithSkuCreator = () =>
  createSelector(
    [getSkusToBuilders],
    skusToBuilders => (sku: string) => sku in skusToBuilders
  );
